<template>
  <div class="phases">
    <header class="container-header max-w-6xl mx-auto mb-6 lg:mb-14">
      <h1 class="title mb-9">
        Enter your Feed Details
      </h1>
      <p class="text-grey-dark text-sm lg:text-lg text-center">
        Create and name the feeding phases of your feed mill. Enter the inclusion level and CP (optional) of your feed's ingredients and the kg feed/animal for each feeding phase.
        Finally, add the nutrient specifications to generate the phase result matrix.
      </p>
      <p class="text-grey-dark text-sm lg:text-lg text-center">
        Note: Enter the inclusion of the Oil/Fat ingredient that applies to your feed which will be used for the cost saving calculation on the next step.
      </p>
    </header>

    <div
      v-if="user.type === 'Advanced' || user.type === 'Administrator' || user.type === 'Super Admin'"
      class="lg:sticky top-0 z-20 flex flex-col lg:flex-row items-center justify-between lg:divide-x  bg-brand-lightest rounded-medium p-3 md:p-5 xl:py-7 xl:pl-16 2xl:pl-32 xl:pr-16 mb-8 md:mb-9"
    >
      <div class="flex flex-col lg:flex-row items-center justify-between lg:divide-x divide-grey-medium">
        <div class="uppercase text-2xl lg:text-xl font-semibold text-semiBlack mb-6 lg:mb-0 lg:pr-4 xl:pr-11">
          digestibility %
        </div>

        <div class="input-wrapper flex flex-1 space-y-6 lg:pl-4 xl:pl-11">
          <div class="flex flex-1 flex-col sm:flex-row lg:space-y-0 sm:space-x-6 lg:space-x-4">
            <form-field-input
                v-for="ingredient in ingredientDigestibility"
                class="flex-1 max-w-max"
                size="medium"
                type="number"
                :isResetValue="ingredient.digestibility !== ingredient.digestibility_default"
                :key="ingredient.id + 'ingredientDigestibility'"
                :value="ingredient.digestibility"
                :disabled="user.type === 'Standard' || user.type === 'Basic' || user.type === 'Regular'"
                :field-label="ingredient.name"
                :tooltip="ingredientDigestibilityTooltip(ingredient.unique_identifier)"
                @reset-value="onRestValueDigestibility(ingredient)"
                @input="onChangeDigestibility($event, ingredient)"
            >
              <template v-slot:icon>%</template>
            </form-field-input>
          </div>
        </div>
      </div>

      <div
          class="text-lg lg:text-sm xl:text-lg self-center flex text-brand-semiMedium cursor-pointer hover:text-brand-semiDark mt-4 xl:mt-0"
          @click="onModalOpenHowItWork"
      >
        <div class="attention how-it-work">
          <span class="icon w-4 h-4 xl:w-6 xl:h-6 mr-1" v-html="icons.question"></span>
        </div>
        How does this work?
      </div>
    </div>

    <div class="container-bg p-4 md:px-8 md:py-11 xl:p-6 2xl:p-14 z-0">
      <div>
        <div class="lg:hidden mb-4">
          <form-field-select
            track-by="id"
            option-label="name"
            disabled-option-value="Select Phase"
            :options="phases"
            @change="onSetActiveTab"
          />
        </div>

        <div class="hidden lg:block">
          <nav class="-mb-px flex space-x-2" aria-label="Tabs">
            <div ref="phasesButtons" class="overflow-x-auto max-w-6xl nav-buttons relative flex space-x-2">
              <button
                v-for="phase in phases"
                :key="phase.id"
                :class="[isActiveTab(phase.id)
                  ? 'text-white bg-brand-medium'
                  : 'text-gray-500 hover:text-gray-700 bg-grey-medium',
                  'whitespace-nowrap py-2.5 px-9 font-medium text-medium rounded-t-lg focus:outline-none'
                ]"
                :disabled="isActiveTab(phase.id)"
                @click="onSetActiveTab(phase)"
              >
                <input
                  v-if="isActiveTab(phase.id) && tabEditable"
                  v-model="phase.name"
                  v-toggle="{
                    exclude: ['editPhaseName'],
                    handler: 'editableTab'
                  }"
                  class="bg-transparent w-28 px-2"
                  type="text"
                  ref="editTabName"
                />

                <span v-else>
                  {{phase.name}}
                </span>

                <span
                  v-if="isActiveTab(phase.id)"
                  ref="editPhaseName"
                  class="icon w-3.5 h-3.5 ml-4"
                  v-html="icons.pencil"
                  @click.stop="onEdit(phase)"
                />

              </button>
            </div>

            <button
              class="text-brand-medium"
              @click="onAddPhase"
            >
              <span class="icon w-3.5 h-3.5" v-html="icons.plus"></span>
              Add Phase
            </button>
          </nav>
        </div>

        <div class="tabs-container">
          <div class="flex flex-col lg:flex-row justify-between lg:bg-grey-light lg:py-4 lg:px-7 mb-8 md:mb-11">
            <div class="text-grey-dark flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 lg:space-x-8 mb-4 lg:mb-0">
              <form-field-input
                v-if="!isActiveEggPoultry"
                v-model="activeTab.feedIntake"
                size="medium"
                :field-label="getFeedIntakeLabel"
              />
            </div>

            <div class="flex justify-between items-center space-x-4 lg:space-x-8">
              <button
                class="btn-danger"
                :disabled="phases.length === 1"
                @click="onModalOpenDeletePhase"
              >
                <span class="icon" v-html="icons.trash"/>

                Delete Phase
              </button>

              <div class="rounded-full bg-white text-center flex flex-col items-center justify-center py-4 px-6 lg:px-16 shadow-lg">
                <p v-if="getSelectedProduct === 'proact360'" class="text-grey-dark text-xs font-medium mb-2.5">
                  <span class="font-bold">ProAct 360<sup>TM</sup></span> Dose
                </p>
                <p v-else class="text-grey-dark text-xs font-medium mb-2.5">
                  <span class="font-bold">ProAct</span> Dose
                </p>

                <p class="font-semibold md:text-xl">
                  {{ activeTab.dose }} g/MT
                </p>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3 xl:gap-6 2xl:gap-10">
            <div class="wrapper">
              <ingredients
                v-if="activeTab.ingredients"
                v-model="activeTab.ingredients"
                @addIngredient="onAddIngredientToActivePhase"
                @removeIngredient="onRemoveIngredientFromActivePhase"
                @calculateSum="ingredientsCalculateSum = $event"
              />
            </div>

            <div class="wrapper">
              <nutrients
                v-if="activeTab.nutritional_values"
                v-model="activeTab.nutritional_values"
              />
            </div>

            <div class="wrapper lg:col-span-2 xl:col-span-1">
              <result
                v-if="activeTab.name"
                v-model="phaseResult"
                :name="activeTab.name"
                @change-nutrient="onChangeResult"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      ref="modal"
      :class="{'modal-how-it-work': isOpenModalHowItWork}"
      :isOpen="isModalOpen"
      :hasCancelButton="isOpenModalDeletePhase"
      @close="onModalCloseAll"
    >


      <template #title>
        <p></p>
        <template v-if="isOpenModalDeletePhase">
          <div class="flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start">
            <div class="attention">
              <span class="icon" v-html="icons.attention"></span>
            </div>

            <h3 class="sub-title text-2xl m-0 text-left">
              You are about to delete <span class="font-medium">{{ activeTab.name }}</span>
              <small>Are you sure you want to erase all the data that have been recorder in your account?</small>
            </h3>
          </div>
        </template>

        <template v-else-if="isOpenModalSumValidation">
          <div class="flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start">
            <div class="attention">
              <span class="icon" v-html="icons.attention"></span>
            </div>

            <h3 class="sub-title text-2xl m-0 text-left">
              The feed raw material total inclusion needs to be <span class="font-medium">100%</span>
              <small>Please adjust the feed composition before proceeding.</small>
            </h3>
          </div>
        </template>

        <template v-else-if="isOpenModalCPValidation">
          <div class="flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start">
            <div class="attention">
              <span class="icon" v-html="icons.attention"></span>
            </div>

            <h3 class="sub-title text-2xl m-0 text-left">
              The CP requirement cannot be zero
              <small>Please adjust the CP requirement value before proceeding.</small>
            </h3>
          </div>
        </template>
      </template>

      <template v-if="isOpenModalHowItWork">
        <div class="flex flex-col justify-start lg:divide-y-2">
          <img src="@/assets/images/how-it-work/slide-1.png" alt="Footer Background" class="mx-auto mb-6 w-full h-auto">
          <img src="@/assets/images/how-it-work/slide-2.png" alt="Footer Background" class="mx-auto mt-6 w-full h-auto">
          <img src="@/assets/images/how-it-work/slide-3.png" alt="Footer Background" class="mx-auto mt-6 w-full h-auto">
        </div>
      </template>

      <template #button>
        <template v-if="isOpenModalDeletePhase">
          <button
              class="remove"
              @click="onDeletePhase"
          >
            Delete Diet
          </button>
        </template>

        <template v-else-if="isOpenModalSumValidation || isOpenModalHowItWork || isOpenModalCPValidation">
          <button
              class="btn-success"
              @click="onModalCloseAll"
          >
            Got it
          </button>
        </template>
      </template>
    </modal>

    <Navigation
      v-bind="{prev: {disabled: false}, next: {disabled: false}}"
      @click="onValidateNextTick"
    >
      <template #save>
        <button
          class="btn-light"
          @click="onSaveDiet"
        >
          Save
        </button>
      </template>

    </Navigation>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import NavigationMixin from '@/mixins/NavigationMixin';
import WindowUnload from '@/mixins/WindowUnload';
import FormFieldInput from '@/components/form/FormFieldInput';
import FormFieldSelect from '@/components/form/FormFieldSelect';
import Ingredients from '@/components/phases/Ingredients';
import Nutrients from '@/components/phases/Nutrients';
import Result from '@/components/phases/Result';
import { v4 as uuidv4 } from 'uuid';
import {mapActions, mapMutations, mapState, mapGetters} from 'vuex';
import debounce from 'lodash.debounce'
import ModalMixin from '@/mixins/ModalMixin';
import Modal from '@/components/Modal';

export default {
  name: 'Phase',

  mixins: [
    ModalMixin,
    NavigationMixin,
    WindowUnload
  ],

  components: {
    FormFieldInput,
    FormFieldSelect,
    Ingredients,
    Nutrients,
    Result,
    Modal,
    Navigation
  },

  data: () => ({
    activeTab: {},
    tabEditable: false,
    localPhases: [],
    ingredientsCalculateSum: 0,
    isOpenModalDeletePhase: false,
    isOpenModalSumValidation: false,
    isOpenModalCPValidation: false,
    isOpenModalHowItWork: false,
  }),

  mounted() {
    if (!Object.keys(this.$store.state.feed.activeDiet).length) {
      this.$router.push({name: 'feed-view'})
      return
    }

    this.onSetActiveTab(this.phases[0])
  },

  methods: {
    ...mapMutations({
      onAddPhaseToActiveDiet: 'feed/addPhaseToActiveDiet',
      onRemovePhaseFromActiveDiet: 'feed/removePhaseFromActiveDiet',
      onChangeCurrentPhase: 'feed/onChangeCurrentPhase',
      onSetIngredientToActiveDietAllPhases: 'feed/setIngredientToActiveDietAllPhases',
      onUnsetIngredientFromActiveDietAllPhases: 'feed/unsetIngredientFromActiveDietAllPhases',
      onSetActiveDiet: 'feed/setActiveDiet',
      setResultMatrix: 'result/setResultMatrix'
    }),

    ...mapActions({
      onCalculatePhaseMatrix: 'phase/onCalculatePhaseMatrix',
      onSavePhase: 'feed/onSaveDiet'
    }),

    isActiveTab(id) {
      return this.activeTab.id === id
    },

    onSetActiveTab(phase) {
      if (typeof phase === 'string') {
        // eslint-disable-next-line eqeqeq
        phase = this.phases.find(el => el.id == phase)
      }

      phase.phaseResult = JSON.parse(JSON.stringify(this.$store.state.phase.phaseResult))

      this.$set(this, 'activeTab', phase)
    },

    onAddPhase() {
      const buttonsWrapper = this.$refs.phasesButtons
      const id = 'temp-dietPhase-' + uuidv4();
      const copyPhase = JSON.parse(JSON.stringify(this.activeTab))

      this.onAddPhaseToActiveDiet({
        ...copyPhase,
        nutritional_values: this.activeTab.nutritional_values.map(nutrient => ({
          ...nutrient,
          id: `temp-${nutrient.id}`
        })),
        name: `Phase ${this.phases.length + 1}`,
        id: id
      })

      this.$nextTick(() => {
        buttonsWrapper.scrollLeft = [...buttonsWrapper.childNodes].reduce((acc, el) => acc + el.offsetWidth, 0)
      })

      this.$set(this, 'activeTab', this.phases[this.phases.length - 1])
    },

    onDeletePhase() {
      const activeTabName = this.activeTab.name

      this.onRemovePhaseFromActiveDiet(this.activeTab.id)

      this.onModalClose()

      this.$toast.open({
        type: 'error',
        message: `Diet <strong>${activeTabName}</strong> was deleted!`
      })

      this.$set(this, 'activeTab', this.phases[this.phases.length - 1] || {})
    },

    editableTab() {
      this.tabEditable = false
    },

    onEdit() {
      this.tabEditable = true

      this.$nextTick(() => {
        this.$refs.editTabName.forEach(el => {
          el.focus()
        })
      })
    },

    onSaveDiet() {
      const activeTabPhaseIndex = this.getIndex(this.phases, this.activeTab.id)
      this.onSavePhase({diet: this.activeDiet}).then(res => {
        const savedDiet = {...this.activeDiet, ...res.diet}
        const diets = this.diets
        diets.splice(this.getIndex(diets, this.activeDiet.id), 1, savedDiet)
        this.localSetActiveDiet(savedDiet)
        this.onSetActiveTab(this.phases[activeTabPhaseIndex])

        this.$toast.open({
          message: `Diet <strong>${res.diet.name}</strong> was saved!`
        })
      })
    },

    onAddIngredientToActivePhase(id) {
      const localIngredients = Array.from(this.activeTab.ingredients)
      const newIngredient = this.$store.state.feed.ingredients[this.getIndex(this.$store.state.feed.ingredients, +id)]

      this.onSetIngredientToActiveDietAllPhases({
        ingredient: {...newIngredient},
        currentPhaseId: this.activeTab.id
      })

      this.localSetActiveDiet(this.$store.state.feed.activeDiet)

      localIngredients.splice(-2, 0, newIngredient)

      this.activeTab.ingredients = localIngredients
    },

    onRemoveIngredientFromActivePhase(id) {
      const localIngredients = Array.from(this.activeTab.ingredients)
      const removedIngredient = this.$store.state.feed.ingredients[this.getIndex(this.$store.state.feed.ingredients, +id)]

      this.onUnsetIngredientFromActiveDietAllPhases({
        ingredient: {...removedIngredient},
        currentPhaseId: this.activeTab.id
      })

      this.localSetActiveDiet(this.$store.state.feed.activeDiet)

      localIngredients.splice(this.getIndex(localIngredients, id), 1)

      this.activeTab.ingredients = localIngredients
    },

    onChangeDigestibility(val, field) {
      this.$store.commit('feed/setDigestibilityToActiveDiet', {val, field})
    },

    onRestValueDigestibility(field) {
      this.$store.commit('feed/setDigestibilityToActiveDiet', {val: field.digestibility_default, field})
    },

    localSetActiveDiet(activeDiet) {
      this.onSetActiveDiet(activeDiet)
      this.setResultMatrix({})
    },

    onChangePhases() {
      const activeTab = JSON.parse(JSON.stringify(this.activeTab || {}))
      const currentIdx = this.phases.findIndex(el => el.id === activeTab.id)
      const phases = this.localPhases
      const changedPhases = []
      const stateActiveTabIngredients = phases[currentIdx].ingredients.filter(ingredient => !ingredient.mandatory)
      const activeTabIngredients = activeTab.ingredients.filter(ingredient => {
        if (ingredient.mandatory) {
          return false
        }
        const stateCurrentIngredient = stateActiveTabIngredients.find(statePhaseIngredient => {
          return ingredient.unique_identifier === statePhaseIngredient.unique_identifier
        })
        return !stateCurrentIngredient || ingredient.cp !== stateCurrentIngredient.cp
      })

      for (let step = phases.length - 1; step >= 0; step--) {
        activeTabIngredients.forEach(ingredient => {
          const phase = phases[step].ingredients.find((nextPhaseIngredient, nextPhaseIngredientKey) => {
            if (ingredient.unique_identifier === nextPhaseIngredient.unique_identifier) {
              phases[step].ingredients[nextPhaseIngredientKey].cp = ingredient.cp
              this.phases[step].ingredients[nextPhaseIngredientKey].cp = ingredient.cp
              return phases[step]
            }
          })
          if (phase) {
            changedPhases.push(this.phases[step]);
          }
        })
      }

      changedPhases.forEach(phase => {
        this.onChangeCurrentPhase(phase)
      })
      this.onChangeCurrentPhase(activeTab)
    },

    onChangeResult: debounce(function(recommendedEnergy) {
      /* eslint-disable no-invalid-this */
      this.activeTab.recommendedEnergy = recommendedEnergy;
    }, 1000),

    ingredientDigestibilityTooltip(identifier) {
      if (identifier === 'soy') {
        return 'This is used for LATAM only and it is a 50:50 mix of Soya Bean Meal & Full Fat Soy'
      }

      return ''
    },

    onValidateNextTick($event) {
      if ($event === 'next' && this.ingredientsCalculateSum !== 100) {
        this.onModalOpenSumValidation()
        return
      } else if ($event === 'next' && !this.activeTab.nutritional_values.find(el => el.nutrientName === 'CP').value) {
        this.onModalOpenCPValidation()
        return
      }
      this.onNavigationClick($event, 'feed-view', 'result')
    },

    onModalOpenHowItWork() {
      this.isOpenModalHowItWork = true
      this.onModalOpen()
    },

    onModalOpenSumValidation() {
      this.isOpenModalSumValidation = true
      this.onModalOpen()
    },

    onModalOpenCPValidation() {
      this.isOpenModalCPValidation = true
      this.onModalOpen()
    },

    onModalOpenDeletePhase() {
      this.isOpenModalDeletePhase = true
      this.onModalOpen()
    },

    onModalCloseAll() {
      this.isOpenModalDeletePhase = false
      this.isOpenModalSumValidation = false
      this.isOpenModalCPValidation = false
      this.isOpenModalHowItWork = false
      this.onModalClose()
    }
  },

  computed: {
    ...mapState({
      ingredientDigestibility: state => state.feed.activeDiet.ingredientDigestibility,
      phaseResult: state => state.phase.phaseResult,
      user: state => state?.auth?.user || {},
    }),

    ...mapGetters({
      diets: 'feed/getDiets',
      isActiveEggPoultry: 'animalType/isActiveEggPoultry',
      activeAnimalType: 'animalType/getActiveAnimalType',
      getSelectedProduct: 'productSelect/getSelectedProduct',
    }),

    activeDiet() {
      return this.$store.state.feed.activeDiet;
    },

    phases() {
      return JSON.parse(JSON.stringify(this.$store.state.feed.activeDiet.diet_phases || []))
    },

    getFeedIntakeLabel() {
      return this.activeAnimalType?.type === 'swine' ? 'FeedIntake (Kg/pig/phase)' : (this.activeAnimalType?.type === 'aqua' ? 'FeedIntake (Kg/fish/phase)' : 'FeedIntake (Kg/bird/phase)')
    }
  },

  watch: {
    'ingredientDigestibility': {
      handler: debounce(function(current, pre) {
        /* eslint-disable no-invalid-this */
        this.onCalculatePhaseMatrix({
          phase: this.activeTab,
          ingredientDigestibility: this.ingredientDigestibility,
          animal: this.activeAnimalType?.type
        })

        if (Object.keys(pre).length) {
          this.setResultMatrix({})
        }
      }, 500),
      deep: true
    },

    'activeTab': {
      handler: debounce(function(current, pre) {
        this.localPhases = JSON.parse(JSON.stringify(this.$store.state.feed.activeDiet.diet_phases || []))
        /* eslint-disable no-invalid-this */
        this.onCalculatePhaseMatrix({
          phase: this.activeTab,
          ingredientDigestibility: this.ingredientDigestibility,
          animal: this.activeAnimalType?.type
        })

        // this.onChangeCurrentPhase(this.activeTab)
        this.onChangePhases()

        if (Object.keys(pre).length) {
          this.setResultMatrix({})
        }
      }, 500),
      deep: true
    },
  }
};
</script>

<style scoped>
>>> .how-it-work svg {
  width: 100%;
  height: auto;
}
>>> .modal-how-it-work .sweet-modal {
  max-width: 70%;
}
</style>
